<template>
  <div>
    <div style="margin: 20px 0 -20px 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="feedback-modify">
      <div class="feedback-modify-main">
        <div class="left">
          <Spin fix v-if="infoLoading"></Spin>
          <div v-else>

            <div class="left-right">
              <div v-if="mainData.id">
<!--                <div class="correction">-->
<!--                  <div class="correction-type">-->
<!--                    ID:<span >({{mainData.id}})</span>-->
<!--                  </div>-->
<!--                  <div class="correction-type" style="display: flex;justify-content: flex-start;">-->
<!--                    <span style="width: 40px">分类:</span>-->
<!--                    <div style="flex: 1;width: 0;">-->
<!--                      <CheckboxGroup v-model="mainData.categoryIds">-->
<!--                        <Checkbox :label="item.value" v-for="item in allCategoryList"><span style="font-size: 14px">{{item.name}}</span></Checkbox>-->
<!--                      </CheckboxGroup>-->
<!--                    </div>-->
<!--&lt;!&ndash;                    <span v-for="(item,index) in mainData.categoryList">{{item.name}}<i v-if="index != mainData.categoryList.length - 1">-</i></span>&ndash;&gt;-->
<!--                  </div>-->
<!--                  <div class="correction-type">-->
<!--                    状态:-->
<!--                    <span>-->
<!--                      <RadioGroup v-model="mainData.status" size="large" style="margin-left: 20px">-->
<!--                        <Radio v-for="(item,index) in statusObj" :label="index" :key="index">{{item}}</Radio>-->
<!--                      </RadioGroup>-->
<!--  &lt;!&ndash;                    {{statusObj[mainData.status]}}&ndash;&gt;-->
<!--                    </span>-->
<!--                  </div>-->
<!--                  <div class="correction-type">-->
<!--                    难度:<span>{{levelObj[mainData.level]}}</span>-->
<!--                  </div>-->
<!--                  <div class="correction-type">-->
<!--                    {{ $t('trainingcamp_train_correction_type') }}:-->
<!--                    <RadioGroup v-model="mainData.type" size="large" style="margin-left: 20px">-->
<!--                      <Radio v-for="item in typesList" :label="item.value" :key="item.value">{{item.name}}</Radio>-->
<!--                    </RadioGroup>-->
<!--                    &lt;!&ndash;                  {{ correctionInfo && typesObj[correctionInfo.type]}}&ndash;&gt;-->
<!--                  </div>-->
<!--                  <div class="correction-tit">-->
<!--                    <div class="correction-left">-->
<!--                      <p class="correction-data-tit">{{ $t('trainingcamp_train_correction_title') }}</p>-->
<!--                    </div>-->
<!--                    <div class="correction-right">-->
<!--                      <Richtxt :height="150"  :dataObj="{val:mainData && mainData.name}" key="feedback" @saveData="(data)=>saveCurData(data,mainData,'name')"></Richtxt>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="correction-option-item" v-for="(item,index) in mainData.options" :key="index">-->
<!--                    <div class="correction-left">-->
<!--                      <p class="correction-data-tit">{{optionNameList[index]}}</p>-->
<!--                      <p class="correction-correct" :class="item.is_correct == '1' ? 'active' : ''" @click="changeCorrect(item,mainData)">{{item.is_correct == '1' ? $t('trainingcamp_train_correction_correct') : $t('trainingcamp_train_correction_set_correct')}}</p>-->
<!--                      <p class="correction-dele" v-if="mainData.options.length > 1" @click="deleOption(index)">{{ $t('trainingcamp_train_correction_delete') }}</p>-->
<!--                    </div>-->
<!--                    <div class="correction-right">-->
<!--                      <Richtxt :dataObj="{val:item.name}" :height="150" @saveData="(data)=>saveCurData(data,item,'name')"></Richtxt>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <Button type="primary" @click="addOption(mainData.options)">{{ $t('trainingcamp_train_correction_add_option') }}</Button>-->
<!--                  <div class="correction-analysis">-->
<!--                    <div class="correction-left">-->
<!--                      <p class="correction-data-tit">{{ $t('trainingcamp_train_correction_analysis') }}</p>-->
<!--                    </div>-->
<!--                    <div class="correction-right">-->
<!--                      <Richtxt :height="150" :dataObj="{val:mainData && mainData.explain}" @saveData="(data)=>saveCurData(data,mainData,'explain')"></Richtxt>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
                <div v-if="mainData.id">
                  <EditData :allData="{data:mainData,allCategory:allCategoryList}" :statusObj="statusObj" :levelObj="levelObj" :typesList="typesList" :sourceFroms="sourceFroms" @changeData="(data)=>{changeData(mainData,data)}"></EditData>
                </div>
              </div>
              <div v-else style="text-align: center">{{ $t('trainingcamp_exam_feedback_no_data') }}</div>
<!--              <div class="btn">-->
<!--                <div style="margin-right: 10px" type="primary" :class="item.status == 1 ? 'active' : ''" v-for="item in mainData.statusList" :key="item.id" @click="selectStatus(item,mainData)">{{ item.name }}</div>-->
<!--              </div>-->
            </div >
            <div class="left-scroll">
              <div class="left-right" v-for="(item,index) in similarData">
                <div>
                  <div v-if="item.id">
                    <EditData :allData="{data:item,allCategory:allCategoryList}" :statusObj="statusObj" :levelObj="levelObj" :typesList="typesList" :sourceFroms="sourceFroms" @changeData="(data)=>{changeData1(index,data)}"></EditData>
                  </div>
<!--                  <div class="correction">-->
<!--                    <div class="correction-type">-->
<!--                      ID:<span >({{item.id}})</span>-->
<!--                    </div>-->
<!--                    <div class="correction-type" style="display: flex;justify-content: flex-start;">-->
<!--                      <span style="width: 40px">分类:</span>-->
<!--                      <div style="flex: 1;width: 0;">-->
<!--                        <CheckboxGroup v-model="item.categoryIds">-->
<!--                          <Checkbox :label="item.value" v-for="item in allCategoryList"><span style="font-size: 14px">{{item.name}}</span></Checkbox>-->
<!--                        </CheckboxGroup>-->
<!--                      </div>-->
<!--                      &lt;!&ndash;                    <span v-for="(item,index) in mainData.categoryList">{{item.name}}<i v-if="index != mainData.categoryList.length - 1">-</i></span>&ndash;&gt;-->
<!--                    </div>-->
<!--&lt;!&ndash;                    <div class="correction-type">&ndash;&gt;-->
<!--&lt;!&ndash;                      分类:<span v-for="(sItem,index) in item.categoryList">{{sItem.name}}<i v-if="index != item.categoryList.length - 1">-</i></span>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                    <div class="correction-type">-->
<!--                      状态:-->
<!--                      <span>-->
<!--&lt;!&ndash;                        {{statusObj[item.status]}}&ndash;&gt;-->
<!--                        <span>-->
<!--                        <RadioGroup v-model="item.status" size="large" style="margin-left: 20px">-->
<!--                          <Radio v-for="(item,index) in statusObj" :label="index" :key="index">{{item}}</Radio>-->
<!--                        </RadioGroup>-->
<!--                          &lt;!&ndash;                    {{statusObj[mainData.status]}}&ndash;&gt;-->
<!--                    </span>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                    <div class="correction-type">-->
<!--                      难度:<span>{{levelObj[item.level]}}</span>-->
<!--                    </div>-->
<!--                    <div class="correction-type">-->
<!--                      {{ $t('trainingcamp_train_correction_type') }}:-->
<!--                      <RadioGroup v-model="item.type" size="large" style="margin-left: 20px">-->
<!--                        <Radio v-for="item in typesList"  :label="item.value" :key="item.value">{{item.name}}</Radio>-->
<!--                      </RadioGroup>-->
<!--                      &lt;!&ndash;                  {{ correctionInfo && typesObj[correctionInfo.type]}}&ndash;&gt;-->
<!--                    </div>-->
<!--                    <div class="correction-tit">-->
<!--                      <div class="correction-left">-->
<!--                        <p class="correction-data-tit">{{ $t('trainingcamp_train_correction_title') }}</p>-->
<!--                      </div>-->
<!--                      <div class="correction-right">-->
<!--                        <Richtxt :height="150"  :dataObj="{val:item.name}" key="feedback" @saveData="(data)=>saveCurData(data,item,'name')"></Richtxt>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="correction-option-item" v-for="(sItem,sIndex) in item.options" :key="sIndex">-->
<!--                      <div class="correction-left">-->
<!--                        <p class="correction-data-tit">{{optionNameList[sIndex]}}</p>-->
<!--&lt;!&ndash;                        <p class="correction-correct" :class="sItem.is_correct == '1' ? 'active' : ''" v-if="sItem.is_correct == '1'" >{{sItem.is_correct == '1' ? $t('trainingcamp_train_correction_correct') : $t('trainingcamp_train_correction_set_correct')}}</p>&ndash;&gt;-->
<!--                        <p class="correction-correct" :class="sItem.is_correct == '1' ? 'active' : ''" @click="changeCorrect(sItem,item)">{{sItem.is_correct == '1' ? $t('trainingcamp_train_correction_correct') : $t('trainingcamp_train_correction_set_correct')}}</p>-->
<!--                        <p class="correction-dele" v-if="item.options.length > 1" @click="deleSimilarOption(sIndex,item.options)">{{ $t('trainingcamp_train_correction_delete') }}</p>-->
<!--                      </div>-->
<!--                      <div class="correction-right">-->
<!--                        <Richtxt :dataObj="{val:sItem.name}" :height="150" @saveData="(data)=>saveCurData(data,sItem,'name')"></Richtxt>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <Button type="primary" @click="addOption(item.options)">{{ $t('trainingcamp_train_correction_add_option') }}</Button>-->
<!--                    <div class="correction-analysis">-->
<!--                      <div class="correction-left">-->
<!--                        <p class="correction-data-tit">{{ $t('trainingcamp_train_correction_analysis') }}</p>-->
<!--                      </div>-->
<!--                      <div class="correction-right">-->
<!--                        <Richtxt :height="150" :dataObj="{val:item.explain}" @saveData="(data)=>saveCurData(data,item,'explain')"></Richtxt>-->
<!--                      </div>-->
<!--                    </div>-->
<!--&lt;!&ndash;                    <div class="btn">&ndash;&gt;-->
<!--&lt;!&ndash;                      <div style="margin-right: 10px" type="primary" :class="sItem.status == 1 ? 'active' : ''" v-for="sItem in item.statusList" :key="sItem.id" @click="selectStatus(sItem,item)">{{ sItem.name }}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                  </div>-->
<!--                -->
                </div>
              </div>
            </div>
          </div>


        </div>
        <div class="right">
          <div class="tab">
            <div class="tab-list">
              <div :class="curTabId == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">
                {{ item.name }}
              </div>
            </div>
          </div>

          <div class="search">
            <Input v-model="keyword"
                   :placeholder="$t('trainingcamp_exam_feedback_search')"
                   clearable
                   search
                   :enter-button="true"
                   @on-enter="getList"
                   @on-search="getList"
            />
          </div>
          <div class="catrgory-search">
            <div>
              <p>一级分类:</p>
              <Select v-model="categoryId" clearable style="margin-right: 10px" @on-change="(data)=>{changeCategory(data,'categoryId')}">
                <Option v-for="item in categoryList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </div>
            <div>
              <p>二级分类:</p>
              <Select v-model="secondCategoryId" clearable style="margin-right: 10px" @on-change="(data)=>{changeCategory(data,'secondCategoryId')}">
                <Option v-for="item in secondCategoryList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </div>
          </div>
          <div class="catrgory-search">
            <div>
              <p>三级分类:</p>
              <Select v-model="threeCategoryId" clearable @on-change="(data)=>{changeCategory(data,'threeCategoryId')}">
                <Option v-for="item in threeCategoryList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </div>
<!--            <div>-->
<!--              <p>批次:</p>-->
<!--              <Select v-model="batchId" clearable @on-change="(data)=>{changeCategory(data,'batchId')}">-->
<!--                <Option v-for="item in batchList" :value="item.batch" :key="item.batch">{{ item.batch }}</Option>-->
<!--              </Select>-->
<!--            </div>-->
          </div>
          <div class="right-top">
            <div class="tit">
              <div>
                {{ $t('trainingcamp_train_answer_sheet') }}
<!--                <Tooltip>-->
<!--                  <Icon type="ios-help-circle-outline" />-->
<!--                  <div slot="content">-->
<!--                    <p>{{ $t('trainingcamp_train_white') }}</p>-->
<!--                    <p>{{ $t('trainingcamp_train_blue') }}</p>-->
<!--                    <p>{{ $t('trainingcamp_train_green') }}</p>-->
<!--                    <p>{{ $t('trainingcamp_train_red') }}</p>-->
<!--                  </div>-->
<!--                </Tooltip>-->
                <div class="count">
                  {{ $t('trainingcamp_exam_feedback_surplus') }}<span>{{ remainingTotal }}</span>{{ $t('trainingcamp_exam_feedback_topic') }}
                </div>
              </div>
              <Button type="text" @click="refresh">{{ $t('trainingcamp_exam_feedback_refresh') }}</Button>
            </div>

            <div class="answer-card">
              <Spin fix v-if="loading"></Spin>
              <div v-else>
                <div class="list" v-if="dataList.length">
                  <div :class="{active:dataList[curIndex].id == item.id,error:item.status == 2,corret:item.status == 1} " v-for="(item,index) in dataList" :key="item.id" @click="changeAnswerCard(index)">{{index + 1}}</div>
                </div>
                <div v-else style="text-align: center">
                  {{ $t('trainingcamp_exam_feedback_no_data') }}
                </div>
                <div class="page">
                  <Page :total="remainingTotal" :current="page" :page-size="pageSize" @on-change="changePage"></Page>
                </div>

              </div>
            </div>
          </div>
          <div class="bot" style="padding-right: 0;">
            <div class="btn">
              <Button size="large" @click="pre">{{$t('trainingcamp_train_pre')}}</Button>
              <Button size="large" @click="next">{{$t('trainingcamp_train_next')}}</Button>
              <Button size="large" type="primary" @click="similarConfirm(2)">稍后处理</Button>
              <Button size="large" style="margin-right: 0;" type="primary" @click="similarConfirm(1)">确定</Button>
            </div>
          </div>
        </div>
      </div>
      <!--    <DeleModal :status="deleModalStatus" :txt="$t('dataset_data_dele_tip')" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>-->
    </div>

<!--    <Modal-->
<!--        v-model="dataOffModal"-->
<!--        title="下架原因"-->
<!--    >-->
<!--      <Form ref="dataOffForm" :model="dataOffForm" :rules="ruleDataOff" :label-width="80">-->
<!--        <FormItem prop="message" label="原因:">-->
<!--          <Input type="textarea" v-model="dataOffForm.message" placeholder="请输入原因">-->
<!--          </Input>-->
<!--        </FormItem>-->

<!--      </Form>-->
<!--      <div slot="footer">-->
<!--        <Button @click="dataOffCancel">取消</Button>-->
<!--        <Button type="primary" @click="dataOffConfirm">确定</Button>-->
<!--      </div>-->
<!--    </Modal>-->
  </div>

</template>

<script>
// import DeleModal from '@/components/deleteModal.vue';
import Richtxt from '@/components/production/richtxt.vue';
import EditData from './components/data/editData'
export default {
  name: "role",
  data(){
    return{
      page:1,
      pageSize:50,
      status:0,  //0待复核1通过2未通过的
      dataList:[],
      loading:true,
      allowLoading:false,
      notAllowLoading:false,
      typesObj:{},
      typesList:[],
      curIndex:0,
      optionList:['A','B','C','D','E','F'],
      correctionModal:false,
      optionNameList:[this.$t('trainingcamp_train_correction_option_a'),this.$t('trainingcamp_train_correction_option_b'),this.$t('trainingcamp_train_correction_option_c'),this.$t('trainingcamp_train_correction_option_d'),this.$t('trainingcamp_train_correction_option_e'),this.$t('trainingcamp_train_correction_option_f'),this.$t('trainingcamp_train_correction_option_g')],
      correctionInfo:{},
      feedbackModal:false,
      formItem:{
        title:'',
        feedback:'',
      },
      ruleValidate: {
        title:[{ required: true, message: this.$t('trainingcamp_train_feedback_tit_placeholder'), trigger: 'blur' }],
        feedback: [
          { required: true, message: this.$t('trainingcamp_train_feedback_cont_placeholder'), trigger: 'blur' }
        ]
      },
      radio:'',
      checkbox:[],
      correctIndex:'',
      correctAnswer:'',
      keyword:'',
      tabList:[],
      remainingTotal:0,
      infoLoading:false,
      deleModalStatus:false,
      modalLoading:false,
      total:0,
      questionType:'',
      correctionDataLoading:false,
      correctionData:{},
      correctionQuestionType:'',
      categoryId:'',
      categoryList:[],
      secondCategoryId:'',
      secondCategoryList:[],
      threeCategoryId:'',
      threeCategoryList:[],
      statusesObj:{},
      batchList:[],
      batchId:'',
      dataStatusObj:{},
      mainData:{},
      similarData:[],
      statusObj:{},
      levelObj:{},
      dataOffModal:false,
      dataOffForm:{
        message:'',
      },
      ruleDataOff:{
        message: [
          { required: true, message: '请输入原因', trigger: 'blur' }
        ],
      },
      curTabId:0,
      allCategoryList:[],
      similarRequestLen:0,
      sourceFroms:{},
    }
  },
  components:{
    Richtxt,
    EditData
    // DeleModal
  },
  created(){
    // this.getList();
    this.getCategoryList();
  },
  methods:{
    changeTab(data){
      this.curTabId = data.id;
      this.page = 1;
      this.getList();
    },
    getCategoryList(){
      let data = {
        category_id:this.categoryId,
        category_second_id:this.secondCategoryId,
        site_id:-1,
      };
      this.loading = true;
      this.api.dataset.categoryForm(data).then((res)=>{
        this.loading = false;

        //一级标签
        this.categoryList = [
          {
            name:this.$t('trainingcamp_train_all'),
            id:'-1'
          },
          ...res.categoryFirstList
        ];
        //二级标签
        this.secondCategoryList = [
          {
            name:this.$t('trainingcamp_train_all'),
            id:'-1'
          },
          ...res.categorySecondList
        ];

        this.threeCategoryList = [
          {
            name:this.$t('trainingcamp_train_all'),
            id:'-1'
          },
          ...res.categoryThirdList
        ]

        this.getList();
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeCategory(data,name){
      this[name] = data;
      if(name == 'categoryId'){
        this.secondCategoryId = '';
        this.threeCategoryId = '';
      }else if(name == 'secondCategoryId'){
        this.threeCategoryId = '';
      }

      this.getCategoryList();
    },
    refresh(){
      this.curIndex = 0;
      this.getList();
    },
    getList(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        // status:this.status, //0待处理1已处理2忽略
        keyword:this.keyword,
        user_id:-1,
        site_id:-1,
        status:this.curTabId
      };
      if(this.threeCategoryId && this.threeCategoryId > -1){
        params.category_id = this.threeCategoryId;
      }else if(this.secondCategoryId && this.secondCategoryId > -1){
        params.category_id = this.secondCategoryId;
      }else if(this.categoryId){
        params.category_id = this.categoryId;
      }

      this.api.dataset.datasetSimilarList(params).then((res)=>{
        this.dataList = res.list;
        this.curIndex = 0;
        this.correctAnswer = '';
        this.loading = false;
        this.remainingTotal = Number(res.count);
        this.tabList = [];
        for(let name in res.statuses){
          this.tabList.push({
            name:res.statuses[name],
            id:name
          })
        }
        this.typesObj = res.data_types;

        this.typesList = [];
        for(let name in res.data_types){
          this.typesList.push({
            name:res.data_types[name],
            value:name
          })
        }

        this.dataStatusObj = res.data_statuses;
        this.getInfo();
      }).catch((err)=>{
        this.loading = false;
      })
    },
    getInfo(){
      if(!this.dataList[this.curIndex]){
        this.correctionInfo = {};
        return;
      }
      let params = {
        data_id:this.dataList[this.curIndex].data_id
      };
      this.infoLoading = true;
      this.api.dataset.datasetSimilarDetail(params).then((res)=>{
        this.infoLoading = false;
        if(res.list.length){
          this.mainData = res.list[0];

          this.$set(this.mainData,'categoryIds',[]);
          for(let name in this.mainData.categoryList){
            this.mainData.categoryIds.push(name)
          }
          this.mainData.labelKeywords = this.mainData.labelKeywords.length ? this.mainData.labelKeywords.map((item)=>{
            return item.label_id
          }) : [];
          this.mainData.labelKeypoints = this.mainData.labelKeypoints.length ? this.mainData.labelKeypoints.map((item)=>{
            return item.label_id
          }) : [];
          let statusList = [
            {
              name:'上架',
              id:1,
              message:'',
              status:0,
            },
            {
              name:'下架',
              id:3,
              message:'',
              status:0,
            },
          ]

          this.$set(this.mainData,'statusList',JSON.parse(JSON.stringify(statusList)));
          this.similarData = res.list.length > 1 ? res.list.splice(1) : [];
          this.similarData.forEach((item)=>{
            item.labelKeywords = item.labelKeywords ? item.labelKeywords.map((sItem)=>{
              return sItem.label_id
            }) : [];
            item.labelKeypoints = item.labelKeypoints ? item.labelKeypoints.map((sItem)=>{
              return sItem.label_id
            }) : [];
            this.$set(item,'statusList',JSON.parse(JSON.stringify(statusList)));
            this.$set(item,'categoryIds',[]);

            for(let name in item.categoryList){
              item.categoryIds.push(name)
            }

          })
        }else{
          this.mainData = {};
          this.similarData = [];
        }

        this.statusObj = res.data_statuses;
        this.levelObj = res.data_levels;
        this.sourceFroms = res.source_froms;


        this.allCategoryList = [];
        for(let name in res.allCategoryList){
          let names = res.allCategoryList[name].map(sItem=>{
            return sItem.name+'(id:'+sItem.id+')'
          }).join('-');
          this.allCategoryList.push({
            name:names,
            value:name
          })
        }

        // this.questionType = this.typesObj[this.correctionInfo.type];
      }).catch((err)=>{
        this.infoLoading = false;
      });
    },
    changeData(data,curData){
      this.mainData = JSON.parse(JSON.stringify(curData));
      console.log(data,curData,'ddddd')
    },
    changeData1(index,curData){
      this.similarData[index] = JSON.parse(JSON.stringify(curData));
    },
    changeAnswerCard(index){
      this.curIndex = index;
      this.getInfo();
    },
    next(){ //下一题
      if(this.curIndex == this.dataList.length - 1){
        this.$Message.warning(this.$t('trainingcamp_train_last_test'));
        return;
      }
      this.curIndex = this.curIndex + 1;
      this.getInfo();
    },
    pre(){  //上一题
      if(this.curIndex == 0){
        this.$Message.warning(this.$t('trainingcamp_train_first_test'));
        return;
      }
      this.curIndex = this.curIndex - 1;
      this.getInfo();
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    saveCurData(curData,data,name){
      data[name] = curData;
    },
    changeCorrect(data,obj){  //修改正确选项
      if(obj.type == 1 || obj.type == 3){ //单选
        if(data.is_correct == '0'){
          obj.options.forEach((item)=>{
            item.is_correct = '0';
          });
          this.$nextTick(()=>{
            data.is_correct = '1';
          })
        }else{
          this.$nextTick(()=>{
            data.is_correct = '0';
          })
        }
      }else{
        data.is_correct == '0'  ? data.is_correct = '1' : data.is_correct = '0';
      }

    },
    addOption(data){  //添加选项
      if(data.length == 6){
        this.$Message.warning(this.$t('trainingcamp_train_option_max'));
        return;
      }
      data.push({
        name:'',
        id:'0',
        is_correct:'0'
      })
    },
    deleOption(index){  //删除选项
      this.mainData.options.splice(index, 1);
    },
    deleSimilarOption(index,data){  //删除选项
      data.splice(index, 1);
    },
    verification(data){
      let status = false;
      if(!data.categoryIds.length){
        this.$Message.warning('请选择分类');
        status = true;
      }
      if(!data.id){
        this.$Message.warning('请选择数据');
        status = true;
      }
      if(!data.name){  //标题
        this.$Message.warning(this.$t('trainingcamp_train_warning_tit'));
        status = true;
      }
      if(data.type == '1' || data.type == '2' || data.type == '3' || data.type == '4') {
        if (!data.options.length) { //选项
          this.$Message.warning(this.$t('trainingcamp_train_warning_add_option'));
          status = true;
        }
        for(let i=0;i<data.options.length;i++){
          if(!data.options[i].name){
            this.$Message.warning(this.$t('trainingcamp_train_warning_input_cont'));
            status = true;
            break;
          }
        }
      }
      if(data.type == '1' || data.type == '2' || data.type == '3') {
        let len = data.options.filter((item) => {
          return item.is_correct == '1';
        }).length
        console.log(data, len, 'lenlenlenlen')
        if (!len) { //正确选项
          this.$Message.warning(this.$t('trainingcamp_train_warning_select'));
          status = true;
        }

        if (data.type == '1' || data.type == '3') {
          if (len > 1) {
            this.$Message.warning('只能选择一个正确答案');
            status = true;
          }
        }
      }
      return status;
    },
    save(data,fn){
      let params = {
        id:data.id,
        name:data.name,
        explain:data.explain,
        options:JSON.stringify(data.options),
        type:data.type,
        level:data.level,
        status:data.status,
        source:data.source,
        label_keyword_ids:data.labelKeywords.join(','),
        label_keypoint_ids:data.labelKeypoints.join(','),
        site_id:'-1',
      };

      params.category_ids = data.categoryIds.join('|');

      this.api.dataset.dataUpdate(params).then((res)=>{
        this.similarRequestLen++;
        console.log()
        if(this.similarRequestLen == this.similarData.length + 1){
          this.datasetSimilarVerify(1);
        }
      })
    },
    submit(data){ //保存并提交
      this.save(data);
    },
    datasetSimilarVerify(status){
      let params = {
        // data_id:this.mainData.id,
        id:this.dataList[this.curIndex].id,
        status:status
      };
      this.api.dataset.datasetDataSimilarVerify(params).then((res)=>{
        this.$Message.success({
          content: '更新成功',
          duration: 3,
        });

        this.dataList[this.curIndex].status = status;
        this.similarRequestLen = 0;
        this.next();
      })
    },
    // datasetSimilarUpdate(){
    //   let params = {
    //     data_id:this.mainData.id
    //   };
    //   this.api.dataset.datasetSimilarUpdate(params).then((res)=>{
    //     this.$Message.success({
    //       content: '更新成功',
    //       duration: 3,
    //     });
    //     this.getList();
    //   })
    // },
    // dataVerify(data){
    //   let selectStatusData = data.statusList.filter((item)=>{
    //     return item.status == 1;
    //   })[0];
    //   let params = {
    //     id:data.id,
    //     status:selectStatusData.id,
    //     message:selectStatusData.message
    //   };
    //   this.api.dataset.datasetDataVerify(params).then((res)=>{
    //     // this.$Message.success('操作成功');
    //
    //     // this.getList();
    //   })
    // },
    // dataOffCancel(){
    //   this.dataOffModal = false;
    // },
    // dataOffConfirm(){
    //   this.$refs['dataOffForm'].validate((valid) => {
    //     if (valid) {
    //       this.curStatusData.status = 1;
    //       this.dataOffModal = false;
    //       this.curStatusData.message = this.dataOffForm.message;
    //     }
    //   })
    // },
    selectStatus(data,arr){
      this.curStatusData = data;

      arr.statusList.forEach((item)=>{
        item.status = 0;
      })
      if(data.id == 3){
        this.dataOffModal = true;
        this.dataOffForm.message = '';
      }else{
        data.status = 1;
      }
    },
    similarConfirm(status){
      if(status == 1){
        // let arr = [];
        // this.mainData.statusList.forEach((item,index)=>{
        //   if(item.status == 1){
        //     arr.push({...item})
        //   }
        // })
        // this.similarData.forEach((item)=>{
        //   item.statusList.forEach((sItem)=>{
        //     if(sItem.status == 1){
        //       arr.push({...item})
        //     }
        //   })
        // })
        // if(arr.length < this.similarData.length + 1){
        //   this.$Message.warning('有数据未处理');
        //   return;
        // }
        let len = 0;
        let status = this.verification(this.mainData);
        if(!status){
          len++;
        }
        this.similarData.forEach((item)=>{
          let status = this.verification(item);
          if(!status){
            len++;
          }
        })
        console.log(len,this.similarData.length,'this.similarData.length')
        if(len == this.similarData.length + 1){
          this.submit(this.mainData);
          this.similarData.forEach((item)=>{
            this.submit(item);
          })
        }

      }else{
        this.datasetSimilarVerify(status);
      }


    }
  }
}
</script>

<style scoped lang="scss">
.feedback-modify{
  padding: 20px;
  font-size: 14px;
  .top-info{
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #dcdee2;
    background: #FFFFFF;
    flex-flow: column;

    .top-info-t{
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      font-size: 18px;
      >div{
        margin-right: 30px;
      }
    }
  }
  .feedback-modify-main{
    display: flex;
    justify-content: space-between;
  }

  .left{
    flex: 1;
    margin-right: 20px;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    min-width: 50px;

    >div{
      display: flex;
      justify-content: flex-start;
    }

    .left-scroll{
      overflow-x: auto;
      white-space: nowrap;
      flex: 1;
    }
    .left-right{
      display: inline-block;
      width: 540px;
      margin-right: 20px;
      //padding:20px 20px 40px 20px;
      //border: 1px solid #dcdee2;
      //background-color: #FFFFFF;
      font-size: 16px;
      position: relative;
      //height: 644px;
      vertical-align: top;
      height: 100%;
    }
    .left-right:last-child{
      margin-right: 0;
    }
  }
  .left-right-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px
  }
  .right{
    width: 400px;
    .tab{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      line-height: 40px;
      font-size: 16px;

      .tab-list{
        border: 1px solid #dcdee2;
        border-radius: 4px;
        overflow: hidden;
        height: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #FFFFFF;
        width: 100%;
        >div{
          flex: 1;
          text-align: center;
          height: 100%;
          //padding: 0 20px;
          border-right: 1px solid #dcdee2;
        }
        >div:last-child{
          border-right: none;
        }
        >div.active{
          background-color: #2d8cf0;
          color: #FFFFFF;
        }
      }

    }
    .search{
      margin-bottom: 20px;
    }
    .catrgory-search{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      >div{
        flex: 1;
        margin-right: 10px;
        >p{
          margin-bottom: 5px;
        }
      }
      >div:last-child{
        margin-right: 0;
      }
    }
    .right-top{
      border: 1px solid #dcdee2;
      background-color: #FFFFFF;


      .tit{
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #dcdee2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        >div{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .count{
            margin-left: 20px;
            font-size: 14px;
            font-weight: normal;
            >span{
              padding: 0 5px;
              color:#2d8cf0;
            }
          }
        }
      }
      .again{
        white-space:normal
      }
      .answer-card{
        margin: 20px 10px;
        height: 450px;
        overflow-y: auto;
        position: relative;
        .list{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          >div{
            width: 50px;
            height: 30px;
            border: 1px solid #dcdee2;
            text-align: center;
            line-height: 30px;
            border-radius: 4px;
            margin: 0 10px 10px 10px;
            cursor: pointer;
          }

          >div.error{
            background-color: #ec808d;
            color:#FFFFFF;
          }
          >div.corret{
            background-color: #19be6b;
            color:#FFFFFF;
          }
          >div.active{
            background-color: #2d8cf0;
            //border: 1px solid #2d8cf0;
            color:#FFFFFF;
          }
          >div.error.active{
            background-color:red;
            border: none;
          }
          >div.corret.active{
            background-color: green;
            border: none;
          }
        }
        .page{
          margin-top: 15px;
          text-align: right;
        }
      }
      .num{
        margin-top: 20px;
        margin-bottom: 10px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        span{
          padding-left:5px;
          padding-right: 2px;
          font-weight: bold;
        }

        .blue{
          color:#19be6b;
        }
        .red{
          color:#ed4014;
        }
      }
    }
  }
  .btn{
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    >div{
      width: 60px;
      text-align: center;
      height: 38px;
      line-height: 38px;
      border-radius: 4px;
      border:1px solid #dcdee2;
      cursor: pointer;
    }
    .active{
      background-color: #2d8cf0;
      color:#FFFFFF;
    }
  }
  .option{
    font-size: 16px;
    //white-space: -moz-pre-wrap;
    white-space:normal;
    height: auto;
  }
  .checkoutOption{
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    white-space:normal;
    height: auto;
  }
}
.bjColor{
  background-color: #dee6de;
}
.nrBjColor{
  background-color: #f0faef !important;
}
.big-font-size{
  font-size: 18px !important;
}
.little-font-size{
  font-size: 14px!important;
}
.middle-font-size{
  font-size: 16px!important;
}
.correction,
.left-right{  //纠错
  //height: 500px;
  //overflow-y: auto;
  .correction-name,
  .tit{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .correction-type{
    margin-bottom: 20px;
    white-space: normal;
  }
  .correction-tit,
  .correction-option-item,
  .correction-analysis,
  .feedback-item{
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    .correction-left,
    .feedback-item-tit{
      width: 70px;
      text-align: right;
      font-size: 14px;
      overflow: hidden;


      .correction-correct{
        margin: 5px 0;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        cursor: pointer;
      }
      .correction-dele{
        float: right;
        width: 50px;
        color:#FFFFFF;
        border-radius: 4px;
        padding: 5px 0;
        text-align: center;
        background-color: #ed4014;
        cursor: pointer;
      }
      .active{
        border: 1px solid #19be6b;
        background-color: #19be6b;
        color:#FFFFFF;
      }
    }
    .correction-right,
    .feedback-item-cont{
      margin-left: 20px;
      flex: 1;
      //border: 1px solid #dcdee2;
    }
  }
  .feedback-item{
    display: block;
  }
  .feedback-item-tit{
    text-align: left !important;
    margin-bottom: 10px;
  }
  .feedback-item-cont{
    margin-left: 0!important;
  }
  .correction-analysis{
    margin-top: 20px;
  }
  .catrgory-search{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.bot{
  //position: fixed;
  margin-top: 20px;
  padding: 20px;
  right: 440px;
  bottom: 0;
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
  z-index: 999;
  //background-color: #FFFFFF;
  .btn{
    display: flex;
    justify-content: flex-start;
    button{
      margin-right: 10px;
    }
    >div{
      width: 60px;
      text-align: center;
      height: 38px;
      line-height: 38px;
    }
  }

  .op{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    >div{
      margin-left: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      >img{
        margin-right: 5px;
        width: 20px;
        height: 20px;
      }
    }

  }
}

</style>
<style>
.data-verify .ivu-checkbox-disabled+span{
  color:#515a6e !important;
}
</style>
